import Frame from './Frame.jsx'
import './App.css';

function App() {
  return (
   <div>
    <Frame/>
   </div>
  );
}

export default App;
